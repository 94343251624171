import React, { useEffect, useState, useRef } from 'react'
import { Row } from '@nextui-org/react'
import { useInView } from 'react-intersection-observer'
import { useRouter } from 'next/router'
import getConfig from 'next/config'
import { useFanmeBackendGet, postBackend } from '@/libs/fanme_backend'

import SideMenuButton from '@/components/molecules/SideMenuButton'
import CreatorStatusButton from '@/components/molecules/CreatorCover/StatusButton'
import CreatorMessage from '@/components/molecules/CreatorCover/Message'
import CreatorStatus from '@/components/molecules/CreatorCover/Status'
import SnsLinkList from '@/components/molecules/SnsLinkList'
import OneBlock from '@/components/molecules/Contents/OneBlock'
import TwoBlock from '@/components/molecules/Contents/TwoBlock'
import ThreeBlock from '@/components/molecules/Contents/ThreeBlock'
import FourBlock from '@/components/molecules/Contents/FourBlock'
import ImageList from '@/components/molecules/Contents/ImageList'
import TextList from '@/components/molecules/Contents/TextList'
import YouTube from '@/components/molecules/Contents/YouTube'
import TikTok from '@/components/molecules/Contents/TikTok'
import HeadLine from '@/components/molecules/Contents/HeadLine'
import Margin from '@/components/molecules/Contents/Margin'
import TextBox from '@/components/molecules/Contents/TextBox'
import SharePopup from '@/components/molecules/SharePopup'
import PageEditToggleButton from '@/components/molecules/PageEditToggleButton'
import LoginMenuButton from '@/components/molecules/LoginMenuButton'
import FollowButton from '@/components/molecules/FollowButton'
import FloatIcon from '@/components/atoms/FloatIcon'

import { COVER_TYPE_PHOTO, COVER_TYPE_VIDEO } from '@/constants'
import { Colors, Gradation } from '@/constants/styles/color'
import { CreatorData, CreatorPopUpData } from '@/types/Creator.type'

import DefaultUserIcon from '@/assets/img/icons/Login.svg'
import Logo from '@/assets/img/logo/LOGO_V_B&W.svg'
import LetterIcon from '@/assets/img/icons/Letter.svg'
import Footer from '@/components/molecules/Footer'

import { useThemeContext } from '@/contexts/ThemeContext'
import { useFollowerContext } from '@/contexts/FollowerContext'

import RankingFanBadgeForCreator from '@/components/molecules/Ranking/Event/RankingFanBadgeForCreator'
import RankingAreaForCreator from '@/components/molecules/Ranking/Event/RankingAreaForCreator'
import { useRankingEvent } from '@/contexts/RankingEventContext'
import EventJoining from '@/assets/img/ranking/event_joining.svg'
import RankingShareDialog from '@/components/molecules/Ranking/Event/RankingShareDialog'
import RankingBoostingDialog from '@/components/molecules/Ranking/Event/RankingBoostingDialog'
import RankingYellGachaDialog from '@/components/molecules/Ranking/Event/YellGacha/RankingYellGachaDialog'
import RankingYellGachaCongratulationsDialog from '@/components/molecules/Ranking/Event/YellGacha/RankingYellGachaCongratulationsDialog'
import RankingYellGachaMissDialog from '@/components/molecules/Ranking/Event/YellGacha/RankingYellGachaMissDialog'
import RankingBadgeTitleDialog from '@/components/molecules/Ranking/Event/RankingBadgeTitleDialog'

import PopUpIcon from '@/assets/img/icons/PopUp.svg'
import FanmeSdkAlert from '@/fanmeSdk/Payment/FanmeSdkAlert'
import Shorts from '@/components/molecules/Contents/Shorts'
import LeftSideMenuButton from '../../LeftSideMenuButton'
import {
  StyledContainer,
  StyledCreatorCoverImg,
  StyledCreatorInfo,
  StyledCreatorInfoIconBase,
  StyledCreatorInfoName,
  StyledCreatorInfoNameWrapper,
  StyledDefaultIconWarapper,
  StyledDummyItem,
  StyledGradationBottom,
  StyledGradationTop,
  StyledHeaderImg,
  StyledImgWrapper,
  StyledInfoIconWrapper,
  StyledLoginBtnWrapper,
  StyledLogoIconField,
  StyledOfficialIcon,
  StyledScrollBar,
  StyledScrollBarBall,
  StyledScrollBarRange,
  StyledScrollBarTransparent,
  StyledScrollText,
  StyledScrollWrapper,
  StyledSnapParent,
  StyledSnsLinkWrapperBottom,
  StyledSnsLinkWrapperTop,
  StyledViewCover,
  StyledViewMain,
  StyledViewMainContents,
  StyledEventJoiningWrapper,
} from './index.style'

interface Props {
  loginUserData?: CreatorData | null
  loginUserPopUpData?: CreatorPopUpData
  data?: CreatorData
  showCreatorPopup?: boolean
  enableCreatorPopup?: boolean
  onCreatorPopupHide?: () => void
  updateCreatorPopupData?: () => void
  fanLetter?: { isOn: boolean; count: string }
}

// eslint-disable-next-line @typescript-eslint/no-unused-vars
const Contents = ({
  loginUserData,
  loginUserPopUpData,
  data,
  onCreatorPopupHide,
  showCreatorPopup = false,
  enableCreatorPopup = true,
  updateCreatorPopupData,
  fanLetter,
}: Props) => {
  const { data: appList } = useFanmeBackendGet<any>('/payment/internal/apps/list')
  const { publicRuntimeConfig } = getConfig()
  const { color: themeColor, isDark } = useThemeContext()
  const followerCtx = useFollowerContext()
  const { isFollowing, setFollow, isFollowOn } = followerCtx
  const router = useRouter()
  const paymentManagementResult = router.query.payment_management_result
  const retrieveGradation = (data: CreatorData | undefined) => {
    const brightness = data?.profile?.cover?.brightness || ''
    switch (brightness) {
      case 'light':
        return Gradation.BK_25
      case 'normal':
        return Gradation.BK_50
      case 'dark':
        return Gradation.BK_75
    }
    return Gradation.BK_50
  }

  // 自身のページを開いたかどうか
  const isPreview =
    loginUserData && data && loginUserData.account_identity === data.account_identity

  // グラデーションの濃さ
  const gradation = retrieveGradation(data)
  // カバー画像表示非表示
  const coverDisplay = data?.profile?.cover?.cover_visibility || false
  // カバー画像
  const cover = data?.profile?.cover?.resource?.resource || undefined
  // カバー画像タイプ（画像/動画）
  const coverType = data?.profile?.cover?.resource?.resource_type || COVER_TYPE_PHOTO
  // ヘッダー画像
  const headerImage = data?.profile?.header_image || ''
  // SNSリンク
  const snsLinks = (data?.profile?.sns_links || []).sort((a, b) => {
    return a.display_order_number > b.display_order_number ? 1 : -1
  })
  // SNSリンクカラー
  const snsColor = data?.profile?.sns_link_color || 'ORIG'
  // コンテンツ
  const contentBlocks: any[] | undefined = data?.content_blocks
  // カバー画像からコンテンツ画面に遷移しているかどうか
  const { ref, inView } = useInView({ rootMargin: '-200px 0px' })
  const { ref: coverRef, inView: coverInView } = useInView()
  // 上部クリエイターステータスの表示・非表示
  const [statusDisplay, setStatusDisplay] = useState(false)
  // SHAREポップの表示・非表示
  const [visiblePopup, setVisiblePopup] = useState(false)
  // 売上申請ポップの表示・非表示
  const [visibleMoneyTransferPopup, setVisibleMoneyTransferPopup] = useState(false)
  // ランキング関連
  const rankingEvent = useRankingEvent()
  const twitterShareButtonRef = useRef<HTMLButtonElement>(null)
  const fanLetterButtonRef = useRef<HTMLImageElement>(null)
  const [visibleRankingSharePopup, setVisibleRankingSharePopup] = useState(false)
  const [visibleBoostingPopUp, setVisibleBoostingPopUp] = useState<boolean>(false)
  const [visibleBadgeDialog, setVisibleBadgeDialog] = useState<boolean>(false)
  const [inRankingEvent, setInRankingEvent] = useState<boolean>(false)
  const [gachaYellCount, setGachaYellCount] = useState<number>(0)
  const [tempGachaYellCount, setTempGachaYellCount] = useState<number>(0)
  const [visibleGachaYellCongratulations, setVisibleGachaYellCongratulations] =
    useState<boolean>(false)
  const [visibleGachaYellMiss, setVisibleGachaYellMiss] = useState<boolean>(false)

  useEffect(() => {
    if (paymentManagementResult && isPreview) {
      setVisibleMoneyTransferPopup(true)
    }
  }, [paymentManagementResult, isPreview])

  // イベント関連
  useEffect(() => {
    if (!rankingEvent.joiningEvent) return
    // NOTE:クリエイターページをわたりあるいたととき、joiningEventの取得(getJoiningEvent())がRankingAreaForCreator内で行われるのだが、
    // 取得が遅延するケースがあるため、クリエイターが一致していることを確認してから処理を行う
    if (rankingEvent.joiningEvent.creator.account_identity !== data?.account_identity) return
    // ランキング参加中
    const currentTime = new Date()
    const eventStartTime = new Date(rankingEvent.joiningEvent.ranking_event.start_at)
    const eventEndTime = new Date(rankingEvent.joiningEvent.ranking_event.end_at)
    if (eventStartTime <= currentTime && currentTime <= eventEndTime) {
      setInRankingEvent(true)
    }
    // イベントブーストポップアップの制御（1時間に1回表示確認する）
    if ((rankingEvent.joiningEvent.ranking_event?.active_ranking_yell_boost?.boost_ratio || 1) <= 1)
      return
    const lastVisibleTime = localStorage.getItem('visibleYellBoostingDialog')
    const oneDayAgo = Date.now() - 1 * 60 * 60 * 1000
    if (!lastVisibleTime || Number(lastVisibleTime) < oneDayAgo) {
      setVisibleBoostingPopUp(true)
      localStorage.setItem('visibleYellBoostingDialog', String(Date.now()))
    }
  }, [rankingEvent.joiningEvent])

  /**
   * スクロールTOPに移動する
   */
  const scrollTopEvent = () => {
    // 上部クリエイターステータスを非表示にする
    setStatusDisplay(false)
    // カバーに戻る
    const elemSnapParent = document.getElementById('snapParent')
    elemSnapParent?.scrollTo(0, 0)
  }

  useEffect(() => {
    const handleScroll = () => {
      // ブラウザの表示領域にファーストビューにある場合はスクロールスナップを有効にし、それより下は無効にする
      const elemSnapParent = document.getElementById('snapParent')
      if (!elemSnapParent) return
      if (elemSnapParent.scrollTop < window.innerHeight) {
        elemSnapParent.style.scrollSnapType = 'y mandatory'
      } else {
        elemSnapParent.style.scrollSnapType = ''
      }
    }
    document.addEventListener('scroll', handleScroll, true)
    return () => document.removeEventListener('scroll', handleScroll, true)
  }, [])

  useEffect(() => {
    //カバービューの要素を代入
    const elemCoverView = document.getElementById('viewCover')
    if (elemCoverView) {
      const elemCreatorGradation = document.getElementById('creatorGradation')
      const elemCreatorInfo = document.getElementById('creatorInfo')

      if (elemCreatorGradation && elemCreatorInfo) {
        // カバービューにコンテンツ（クリエイター情報と、グラデーション）を配置
        elemCreatorGradation.style.height =
          elemCoverView.clientHeight - window.innerHeight + elemCreatorInfo.clientHeight + 'px'

        elemCreatorInfo.style.bottom = elemCoverView.clientHeight - window.innerHeight + 'px'
      }
    }
  }, [data])

  useEffect(() => {
    if (coverType === COVER_TYPE_VIDEO && cover) {
      const video = document.createElement('video')
      video.src = cover
      video.addEventListener('progress', () => {
        const coverVideoElem = document.getElementById('coverVideo')
        if (coverVideoElem) {
          coverVideoElem.style.aspectRatio = `${video.videoWidth} / ${video.videoHeight}`
        }
      })
    }
  }, [coverType, cover])

  const gotoDigitalFanLetter = async () => {
    const digitalFanLetter = appList.items.find((app: any) => app['url_key'] === 'dfl')
    let url

    if (!url) {
      if (digitalFanLetter.require_setup) {
        router.push(`/apps/start/${digitalFanLetter.url_key}`)
        return
      }
      const res = await postBackend(`/payment/internal/apps/${digitalFanLetter.url_key}/install`, {
        name: `${loginUserData?.name}の${digitalFanLetter.name}`,
      })
      if (res.data) {
        url = `${publicRuntimeConfig.FRONT_URL}/@${loginUserData?.account_identity}/apps/${res.data.app_url_key}${res.data.seller_url_key}?from=letter_reminder`
      }
    }
    if (url) {
      window.location.href = url
    }
  }

  if (!data) return <></>

  return (
    <>
      <StyledContainer displayStyle={router.query.h === 'off' ? 'none' : 'flex'}>
        {loginUserPopUpData?.enable && enableCreatorPopup ? (
          <LeftSideMenuButton
            initialVisible={showCreatorPopup}
            enable={enableCreatorPopup}
            onHide={onCreatorPopupHide}
            icon={
              <StyledDefaultIconWarapper bgColor={Colors.BLACK}>
                <PopUpIcon fill={Colors.WHITE} style={{ width: '20px', height: '20px' }} />
              </StyledDefaultIconWarapper>
            }
            popUpData={loginUserPopUpData}
            updateCreatorPopupData={updateCreatorPopupData}
          />
        ) : (
          <StyledDummyItem />
        )}

        {isPreview ? (
          <PageEditToggleButton creatorId={data.account_identity} isEdit={false} />
        ) : (
          <CreatorStatusButton
            creatorName={data.name}
            officialFlag={data.profile?.official_flg}
            avatarImg={!data.icon ? '' : data.icon}
            onClick={scrollTopEvent}
            statusDisplay={statusDisplay}
            onClickShare={() => setVisiblePopup(true)}
          />
        )}

        {!loginUserData?.account_identity ? (
          <StyledLoginBtnWrapper>
            <LoginMenuButton />
          </StyledLoginBtnWrapper>
        ) : (
          <>
            <SideMenuButton
              icon={
                !loginUserData?.icon ? (
                  <StyledDefaultIconWarapper>
                    <DefaultUserIcon style={{ width: '16px', height: '16px' }} />
                  </StyledDefaultIconWarapper>
                ) : (
                  <StyledImgWrapper width={34} height={34}>
                    <StyledCreatorCoverImg src={loginUserData.icon} alt="image" />
                  </StyledImgWrapper>
                )
              }
              loginUserData={loginUserData}
              officialFlag={loginUserData?.profile?.official_flg}
            />
            {isPreview && !!fanLetter?.isOn && (
              <FloatIcon
                cls="absolute right-1 top-[66px] cursor-pointer"
                newMsgs={fanLetter?.count}
                onClick={gotoDigitalFanLetter}
              >
                <LetterIcon />
              </FloatIcon>
            )}
          </>
        )}
      </StyledContainer>

      <StyledSnapParent id="snapParent">
        {coverDisplay && (
          <StyledViewCover
            id="viewCover"
            ref={coverRef}
            bgImage={coverType === COVER_TYPE_PHOTO ? cover : ''}
            bgColor={cover || ''}
          >
            {coverType === COVER_TYPE_VIDEO && (
              <video
                src={cover}
                muted
                loop
                autoPlay
                playsInline
                style={{
                  width: '100%',
                  height: '100%',
                  position: 'absolute',
                  top: 0,
                  bottom: 0,
                  margin: 'auto',
                  background: Colors.BLACK,
                }}
                id="coverVideo"
              />
            )}
            {cover === '' && (
              <StyledLogoIconField>
                <Logo fill={Colors.LIGHT_GRAY_BLUE} style={{ width: '251px', height: '160px' }} />
              </StyledLogoIconField>
            )}
            <StyledGradationTop gradation={cover === '' ? Gradation.BK_15 : gradation} />
            <StyledGradationBottom
              id="creatorGradation"
              gradation={cover === '' ? Gradation.BK_15 : gradation}
            />

            <StyledCreatorInfo id="creatorInfo">
              {inRankingEvent && (
                <StyledEventJoiningWrapper>
                  <EventJoining style={{ height: '32px' }} />
                </StyledEventJoiningWrapper>
              )}
              <StyledInfoIconWrapper>
                {!data.icon ? (
                  <StyledCreatorInfoIconBase>
                    <DefaultUserIcon
                      style={{ width: '32px', height: '32px', filter: 'brightness(0) invert(1)' }}
                    />
                  </StyledCreatorInfoIconBase>
                ) : (
                  <StyledImgWrapper width={64} height={64}>
                    <StyledCreatorCoverImg src={data.icon} alt="image" />
                  </StyledImgWrapper>
                )}
              </StyledInfoIconWrapper>

              <StyledCreatorInfoNameWrapper>
                <StyledCreatorInfoName className="jp-title middle">
                  {data.name}
                  {data.profile?.official_flg && <StyledOfficialIcon />}
                </StyledCreatorInfoName>
                {isFollowOn && (
                  <Row justify="center" align="center" css={{ marginTop: 10 }}>
                    <FollowButton
                      isPreview={isPreview}
                      following={isFollowing}
                      link={`${publicRuntimeConfig.FRONT_URL}/follow`}
                      uid={data.uid}
                      setFollow={setFollow}
                      isDark={isDark}
                    />
                  </Row>
                )}
              </StyledCreatorInfoNameWrapper>

              {snsLinks.length > 0 && (
                <StyledSnsLinkWrapperTop>
                  <SnsLinkList snsLinks={snsLinks} snsColor={snsColor} margin="0" isWHIcon />
                </StyledSnsLinkWrapperTop>
              )}

              <StyledScrollWrapper>
                <StyledScrollBarRange>
                  <StyledScrollBar />
                  <StyledScrollBarTransparent>
                    <StyledScrollBarBall />
                  </StyledScrollBarTransparent>
                </StyledScrollBarRange>
                <StyledScrollText>SCROLL</StyledScrollText>
              </StyledScrollWrapper>
            </StyledCreatorInfo>
          </StyledViewCover>
        )}
        <StyledViewMain id="viewMain" ref={ref} hasHeaderImg={!!headerImage}>
          {headerImage ? (
            <StyledHeaderImg src={headerImage} />
          ) : (
            <div className="w-full aspect-[360/152]" />
          )}
          <StyledViewMainContents bgColor={themeColor}>
            <CreatorStatus
              contentsView={inView && !coverInView}
              creatorName={data.name}
              officialFlag={data.profile?.official_flg}
              avatarImg={!data.icon ? '' : data.icon}
              changeState={setStatusDisplay}
              onClickShare={() => setVisiblePopup(true)}
            />
            {isFollowOn && (
              <Row justify="center" align="center" css={{ marginTop: 10 }}>
                <FollowButton
                  isPreview={isPreview}
                  following={isFollowing}
                  link={`${publicRuntimeConfig.FRONT_URL}/follow`}
                  isDark={isDark}
                  setFollow={setFollow}
                  uid={data.uid}
                />
              </Row>
            )}

            {/* ランキングバッヂ */}
            <RankingFanBadgeForCreator
              creatorData={data}
              setVisibleBadgeDialog={setVisibleBadgeDialog}
            />

            {/* ランキングエリア */}
            <RankingAreaForCreator
              loginUserData={loginUserData}
              creatorData={data}
              setVisibleRankingSharePopup={setVisibleRankingSharePopup}
              setGachaYellCount={setGachaYellCount}
              twitterShareButtonRef={twitterShareButtonRef}
              fanLetterButtonRef={fanLetterButtonRef}
            />

            <CreatorMessage message={data.profile?.bio} />

            {snsLinks.length > 0 ? (
              <StyledSnsLinkWrapperBottom>
                <SnsLinkList snsLinks={snsLinks} snsColor={snsColor} margin="32px auto 12px" />
              </StyledSnsLinkWrapperBottom>
            ) : (
              <div className="mt-2" />
            )}

            {contentBlocks &&
              contentBlocks.map((block, i) => {
                if (!block.displayable) return
                const key = `b-${block.id}-${i}`
                switch (block.content_block_type.name) {
                  case 'one_block':
                    return <OneBlock key={key} contents={block.content_block_details} />
                  case 'two_blocks':
                    return <TwoBlock key={key} contents={block.content_block_details} />
                  case 'three_blocks':
                    return <ThreeBlock key={key} contents={block.content_block_details} />
                  case 'four_blocks':
                    return <FourBlock key={key} contents={block.content_block_details} />
                  case 'image_list':
                    return (
                      <ImageList
                        id={block.content_block_details[0].id}
                        key={key}
                        imageSrc={block.content_block_details[0].icon}
                        title={
                          block.content_block_details[0].title === 'undefined'
                            ? ''
                            : block.content_block_details[0].title
                        }
                        link={block.content_block_details[0].url}
                        description={
                          block.content_block_details[0].description === 'undefined'
                            ? ''
                            : block.content_block_details[0].description
                        }
                      />
                    )
                  case 'text_list':
                    return (
                      <TextList
                        id={block.content_block_details[0].id}
                        key={key}
                        title={
                          block.content_block_details[0].title === 'undefined'
                            ? ''
                            : block.content_block_details[0].title
                        }
                        link={block.content_block_details[0].url}
                        description={
                          block.content_block_details[0].description === 'undefined'
                            ? ''
                            : block.content_block_details[0].description
                        }
                      />
                    )
                  case 'youtube':
                    return (
                      <YouTube
                        id={block.content_block_details[0].id}
                        key={key}
                        title={
                          block.content_block_details[0].title === 'undefined'
                            ? ''
                            : block.content_block_details[0].title
                        }
                        link={block.content_block_details[0].url}
                        description={
                          block.content_block_details[0].description === 'undefined'
                            ? ''
                            : block.content_block_details[0].description
                        }
                      />
                    )
                  case 'shorts':
                    return (
                      <Shorts
                        id={block.content_block_details[0].id}
                        key={key}
                        title={
                          block.content_block_details[0].title === 'undefined'
                            ? ''
                            : block.content_block_details[0].title
                        }
                        link={block.content_block_details[0].url}
                        description={
                          block.content_block_details[0].description === 'undefined'
                            ? ''
                            : block.content_block_details[0].description
                        }
                      />
                    )
                  case 'tiktok':
                    return (
                      <TikTok
                        id={block.content_block_details[0].id}
                        key={key}
                        link={block.content_block_details[0].url}
                      />
                    )
                  case 'headline':
                    return <HeadLine key={key} content={block.content_block_details[0]} />
                  case 'margin':
                    return <Margin key={key} content={block.content_block_details[0]} />
                  case 'text_box':
                    return (
                      <TextBox
                        key={key}
                        title={
                          block.content_block_details[0].title === 'undefined'
                            ? ''
                            : block.content_block_details[0].title
                        }
                        description={
                          block.content_block_details[0].description === 'undefined'
                            ? ''
                            : block.content_block_details[0].description
                        }
                      />
                    )
                }
              })}

            <Footer disableItems />
          </StyledViewMainContents>
          <input
            hidden
            value={data?.profile?.theme_color || 'Light'}
            id="userThemeColor"
            readOnly
          />
        </StyledViewMain>
      </StyledSnapParent>
      <SharePopup
        visible={visiblePopup}
        qrCodeConfig={{ image: data.icon, size: 360 }}
        onClose={() => setVisiblePopup(false)}
        accountIdentity={data.account_identity}
        creatorName={data.name}
      />

      <FanmeSdkAlert
        // GMOへの売上申請の結果がPaymentから帰ってきます
        // 3:成功 それ以外:振込失敗もしくは手続き中にキャンセルした場合になります。
        text={paymentManagementResult === `3` ? `売上申請が完了しました` : `売上申請が失敗しました`}
        visible={visibleMoneyTransferPopup}
        checkIconOn={true}
        closeBtnText="閉じる"
        onClose={() => {
          setVisibleMoneyTransferPopup(false)
        }}
        actionButtonColor={Colors.BLACK}
        actionButtonTextColor={Colors.WHITE}
        actionButtonBorderColor={Colors.WHITE}
      />

      <RankingShareDialog
        // ランキングイベントのシェアダイアログ
        visible={visibleRankingSharePopup}
        ranking_yell_boost={rankingEvent.joiningEvent?.ranking_event?.active_ranking_yell_boost}
        onButtonClose={() => {
          setVisibleRankingSharePopup(false)
        }}
        onAction={() => {
          twitterShareButtonRef.current?.click()
          setVisibleRankingSharePopup(false)
        }}
      />

      {rankingEvent.joiningEvent?.ranking_event?.active_ranking_yell_boost && (
        <RankingBoostingDialog
          // ランキングブーストダイアログ
          visible={visibleBoostingPopUp}
          ranking_yell_boost={rankingEvent.joiningEvent?.ranking_event?.active_ranking_yell_boost}
          onButtonClose={() => {
            setVisibleBoostingPopUp(false)
          }}
          onShare={() => {
            twitterShareButtonRef.current?.click()
            setVisibleBoostingPopUp(false)
          }}
          onFanLetter={() => {
            fanLetterButtonRef.current?.click()
            setVisibleBoostingPopUp(false)
          }}
        />
      )}

      {rankingEvent.joiningEvent && gachaYellCount !== 0 && (
        <RankingYellGachaDialog
          // ルーレット
          yellCount={gachaYellCount} // ガチャ数が0以外というのがルーレットの発動条件
          boostRatio={
            rankingEvent.joiningEvent?.ranking_event?.active_ranking_yell_boost?.boost_ratio || 1
          }
          onNext={() => {
            // おめでとう、またはハズレを表示
            if (gachaYellCount > 0) {
              setVisibleGachaYellCongratulations(true)
            } else {
              setVisibleGachaYellMiss(true)
            }
            // ガチャ数をリセット（つまりダイアログを閉じる
            setTempGachaYellCount(gachaYellCount)
            setGachaYellCount(0)
          }}
        ></RankingYellGachaDialog>
      )}
      {rankingEvent.joiningEvent && (
        <RankingYellGachaCongratulationsDialog
          // おめでとうダイアログ
          visible={visibleGachaYellCongratulations}
          yellCount={tempGachaYellCount}
          boostRatio={
            rankingEvent.joiningEvent?.ranking_event?.active_ranking_yell_boost?.boost_ratio || 1
          }
          creatorData={data}
          hashtags={
            rankingEvent.joiningEvent?.ranking_event?.share_hashtags
              ?.split(',')
              .map(tag => `${tag}\n`) || []
          }
          onClose={() => {
            setVisibleGachaYellCongratulations(false)
          }}
        ></RankingYellGachaCongratulationsDialog>
      )}
      {rankingEvent.joiningEvent && (
        <RankingYellGachaMissDialog
          // はずれダイアログ
          visible={visibleGachaYellMiss}
          creatorData={data}
          hashtags={
            rankingEvent.joiningEvent?.ranking_event?.share_hashtags
              ?.split(',')
              .map(tag => `${tag}\n`) || []
          }
          onClose={() => {
            setVisibleGachaYellMiss(false)
          }}
        ></RankingYellGachaMissDialog>
      )}

      <RankingBadgeTitleDialog
        // バッヂ画像ダイアログ
        visible={visibleBadgeDialog}
        imageSrc={data?.user_badge?.badge.image_url || ''}
        eventId={data?.user_badge?.badge.ranking_event.event_identity || ''}
        creatorImageSrc={data?.user_badge?.creator.icon || ''}
        creatorName={data?.user_badge?.creator.name || ''}
        creatorId={data?.user_badge?.creator.account_identity || ''}
        onButtonClose={() => {
          setVisibleBadgeDialog(false)
        }}
      />
    </>
  )
}

export default Contents
