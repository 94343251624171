import React, { useState, useEffect } from 'react'
import { useInView } from 'react-intersection-observer'
import styled from '@emotion/styled'
import { Colors } from '@/fanmeUi/constant'
import {
  StylePreviewContent,
  StyleContentText,
} from '../../AddHeadLineDetail/Modal/Styles/HeadLineStyle'
import { StyledListContents } from '../index.style'

interface ContentStyle {
  style?: ContentMixin
  icon?: string
  title?: string
}

interface Props {
  content: ContentStyle
}

type ContentMixin = {
  background?: string
  borderColor?: string
  // bgColor?: string
  border?: string
  after?: string
  before?: string
  borderRadius?: string
  backgroundColor?: string
  boxShadow?: string
  color?: string
  contentMargin?: string
  id?: string
}
const StyleHeadLine = styled.div<ContentMixin>`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 44px;
  white-space: nowrap;
  border-radius: ${p => p.borderRadius ?? '0'};
  margin: ${p => p.contentMargin ?? ''};
  border: ${p => (p.border ? p.border : '')};
  border-color: ${p => p.borderColor ?? ''};
  box-shadow: ${p => p.boxShadow ?? ''};
  background-image: ${p => (p.background ? `url(${p.background})` : '')};
  background-color: ${p => p.backgroundColor ?? Colors.TRANSPARENT};
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  ${p => {
    const { after, before } = p
    if (after && before) {
      if (p.backgroundColor === undefined && p.background) {
        return `
        position: relative;
        &:before,&:after {
          content: '';
          position: absolute;
          left: 50%;
        }
        &:before {
          bottom: -15px;
          margin-left: -12px;
          border: 11px solid transparent;
          border-top: ${before ? before : `9px solid ${Colors.WHITE}`};
          z-index: 2;
        }
        &:after {
          bottom: -11px;
          margin-left: -7px;
          border: 6px solid transparent;
          border-top: ${after ? after : `5px solid ${Colors.WHITE}`};
          z-index: 1;
        }
        `
      } else {
        return `
        position: relative;
        &:before,&:after {
          content: '';
          position: absolute;
          left: 50%;
        }
        &:before {
          bottom: -15px;
          margin-left: -12px;
          border: 11px solid transparent;
          border-top: ${before ? before : `9px solid ${Colors.WHITE}`};
          z-index: 2;
        }
        &:after {
          bottom: -11px;
          margin-left: -7px;
          border: 6px solid transparent;
          border-top: ${after ? after : `5px solid ${Colors.WHITE}`};
          z-index: 1;
        }
        `
      }
    }
    return null
  }}
`
//mainに表示する
const HeadLine = ({ content }: Props) => {
  const { ref, inView } = useInView()
  const [mainContent, setMainContent] = useState<ContentMixin & { image?: string }>({})

  const setMainContentWithDefault = (style: ContentStyle) => {
    const {
      borderRadius,
      backgroundColor,
      border,
      borderColor,
      boxShadow,
      color,
      before,
      after,
      id,
    }: ContentMixin = style.style || {}
    const image = style.icon
    setMainContent({
      image,
      borderRadius,
      backgroundColor,
      border,
      borderColor,
      boxShadow,
      color,
      before,
      after,
      id,
    })
  }

  const initializeMainContent = () => {
    setMainContent(prev => ({
      ...prev,
      borderRadius: '16px',
      backgroundColor: Colors.BK_GRAY,
    }))
  }

  useEffect(() => {
    if (content.icon && content.style === null && content.title === '') {
      initializeMainContent()
    } else {
      setMainContentWithDefault(content)
    }
  }, [content])

  const {
    image,
    backgroundColor,
    border,
    borderColor,
    borderRadius,
    boxShadow,
    before,
    after,
    id,
    color,
  } = mainContent
  return (
    <StyledListContents ref={ref} inView={inView}>
      <StylePreviewContent>
        <StyleHeadLine
          background={image}
          borderRadius={borderRadius}
          border={border}
          borderColor={borderColor}
          boxShadow={boxShadow}
          backgroundColor={backgroundColor}
          before={before}
          after={after}
          id={id}
        >
          <StyleContentText color={color}>{content?.title}</StyleContentText>
        </StyleHeadLine>
      </StylePreviewContent>
    </StyledListContents>
  )
}

export default HeadLine
