import React from 'react'
import styled from '@emotion/styled'
import { Colors } from '@/constants/styles/color'
import XShareIcon from '@/assets/img/ranking/x_share_icon.svg'
import { IRankingYellBoost } from '@/contexts/RankingEventContext'

const StyledContainer = styled.div`
  position: absolute;
  max-width: 480px;
  width: 100vw;
  height: 100vh;
  height: 100svh;
  left: 0;
  top: 0;
  z-index: 10002;
  padding: 0;
  display: flex;
`

const StyledAlertBG = styled.div`
  background: rgba(0, 0, 0, 0.3);
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
`

const StyledAlert = styled.div`
  display: flex;
  position: relative;
  background: ${Colors.WHITE};
  border-radius: 16px;
  flex-direction: column;
  margin: auto;
  width: calc(100% - 80px);
  z-index: 10003;
  padding: 0;
  box-shadow: 4px 4px 8px rgba(0, 0, 0, 0.25), -1px -1px 8px rgba(0, 0, 0, 0.25);
`

const StyledXShareIcon = styled.div`
  width: 64px;
  height: 64px;
  padding: 4px;
  background: ${Colors.WHITE};
  border-radius: 50%;
  margin: -32px auto 0 auto;
`

const StyledMain = styled.div`
  margin: 35px 24px;
`

const StyledText = styled.div`
  color: ${Colors.BLACK};
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 20px;
  text-align: center;
  white-space: pre-wrap;
  .orange-text {
    color: ${Colors.ORANGE};
    .emphasis-text {
      font-size: 18px;
    }
  }
`

const StyledActionButton = styled.button`
  height: 48px;
  background: ${Colors.BLACK};
  border: ${Colors.BLACK};
  color: ${Colors.WHITE};
  border-radius: 24px;
  margin: 0 24px 24px 24px;
  font-style: normal;
  font-weight: 700;
  font-size: 14px;
  line-height: 14px;
`

const StyledCloseButtonContainer = styled.div`
  position: absolute;
  bottom: -65px;
  left: 50%;
  transform: translateX(-50%);
  z-index: 10003;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 88px;
  padding: 12px 16px;
  font-family: 'Noto Sans JP';
  font-style: normal;
  font-weight: 700;
  font-size: 12px;
  line-height: 12px;
  color: ${Colors.BLACK};
  background: ${Colors.WHITE};
  border-radius: 16px;
  box-shadow: 4px 4px 8px rgba(0, 0, 0, 0.25), -1px -1px 8px rgba(0, 0, 0, 0.25);
`

interface Props {
  visible: boolean
  ranking_yell_boost: IRankingYellBoost | undefined
  onAction?: () => void
  onButtonClose?: () => void
}

const RankingShareDialog = ({ visible, ranking_yell_boost, onButtonClose, onAction }: Props) => {
  if (!visible) return <></>

  const postableTapYellCount = (): number => {
    return 100 * (ranking_yell_boost?.boost_ratio || 1)
  }
  const postableShareYellCount = (): number => {
    return 200 * (ranking_yell_boost?.boost_ratio || 1)
  }

  return (
    <>
      <StyledContainer>
        <StyledAlertBG />
        <StyledAlert>
          <StyledXShareIcon>
            <XShareIcon />
          </StyledXShareIcon>

          <StyledMain>
            <StyledText>
              タップで送れるエールは、
              <br />
              １日あたり最大{postableTapYellCount()}エールまでです！
              <br />
              <span className="orange-text">
                Xへシェアして{' '}
                <span className="emphasis-text">{postableShareYellCount()}エール</span>
              </span>{' '}
              を送りませんか？
            </StyledText>
          </StyledMain>

          <StyledActionButton onClick={onAction}>シェアする</StyledActionButton>
          {onButtonClose && (
            <StyledCloseButtonContainer
              onClick={() => {
                onButtonClose()
              }}
            >
              閉じる
            </StyledCloseButtonContainer>
          )}
        </StyledAlert>
      </StyledContainer>
    </>
  )
}

export default RankingShareDialog
