import React, { useEffect, useState } from 'react'
import { useInView } from 'react-intersection-observer'
import { Colors, contentShadow } from '@/constants/styles/color'
import { event } from '@/pages/api/gtag'
import { useThemeContext } from '@/contexts/ThemeContext'
import { StyledContentText, StyledContents, StyledImage, StyledLink } from '../index.style'

interface Props {
  contents: Array<{
    id: number
    url?: string
    icon?: string
    title?: string
  }>
}

const OneBlock = ({ contents }: Props) => {
  const { themeColors, isDark } = useThemeContext()

  const [content, setContent] = useState<any>()
  const { ref, inView } = useInView()
  const [imgHeight, setImgHeight] = useState<string>('auto')

  const imageOnload = (src: string) => {
    return new Promise((resolve, reject) => {
      const image = new Image()
      image.src = src
      image.onload = () => {
        resolve(image)
      }
      image.onerror = error => {
        reject(error)
      }
    })
  }

  const onClick = (id: number) => {
    event({
      action: 'click',
      category: 'creator_page_click',
      label: 'links',
      value: id,
    })
  }

  useEffect(() => {
    if (contents.length > 0) {
      setContent(contents[0])
      if (contents[0]?.icon) {
        imageOnload(contents[0].icon)
          .then(res => {
            const imageElem = res as HTMLImageElement
            // 最大幅328px
            const height = (328 / imageElem.width) * imageElem.height
            setImgHeight(`${height}px`)
          })
          .catch(function (error) {
            console.log(error)
          })
      }
    }
  }, [contents])

  return (
    <StyledContents ref={ref} inView={inView}>
      <div>
        {content?.url || content?.icon ? (
          <StyledLink
            href={content?.url || content?.icon}
            target="_blank"
            onClick={() => onClick(content.id)}
            className={content?.url?.includes('/apps/') ? 'ranking-anchor' : ''}
          >
            {content?.icon ? (
              <>
                <div
                  style={{
                    boxShadow: content?.url
                      ? `${isDark ? contentShadow.dark : contentShadow.light}`
                      : '',
                    borderRadius: '16px',
                    position: 'relative',
                    width: '100%',
                    maxWidth: '328px',
                    height: imgHeight,
                  }}
                >
                  <StyledImage src={content?.icon} alt="image" imgHeight={imgHeight} />
                </div>
              </>
            ) : (
              <div
                style={{
                  boxShadow: content?.url
                    ? `${isDark ? contentShadow.dark : contentShadow.light}`
                    : '',
                  borderRadius: '16px',
                }}
              >
                <div
                  style={{
                    width: 'calc(328 / 375 * 100vw)',
                    maxWidth: '328px',
                    height: '328px',
                    objectFit: 'cover',
                    borderRadius: '16px',
                    background: Colors.BK_GRAY,
                  }}
                />
              </div>
            )}
            <StyledContentText color={themeColors.text}>
              {content?.title === 'undefined' ? '' : content?.title}
            </StyledContentText>
          </StyledLink>
        ) : (
          <>
            {content?.icon ? (
              <div
                style={{
                  boxShadow: '0px 5px 15px rgba(0, 0, 0, 0.08)',
                  borderRadius: '16px',
                  width: '100%',
                  maxWidth: '328px',
                  height: imgHeight,
                }}
              >
                <StyledImage src={content?.icon} alt="image" imgHeight={imgHeight} />
              </div>
            ) : (
              <div
                style={{
                  boxShadow: '0px 5px 15px rgba(0, 0, 0, 0.08)',
                  borderRadius: '16px',
                }}
              >
                <div
                  style={{
                    width: 'calc(328 / 375 * 100vw)',
                    maxWidth: '328px',
                    height: '328px',
                    objectFit: 'cover',
                    borderRadius: '16px',
                    background: Colors.BK_GRAY,
                  }}
                />
              </div>
            )}
            <StyledContentText color={themeColors.text}>
              {content?.title === 'undefined' ? '' : content?.title}
            </StyledContentText>
          </>
        )}
      </div>
    </StyledContents>
  )
}

export default OneBlock
