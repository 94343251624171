import React from 'react'
import { useInView } from 'react-intersection-observer'
import { event } from '@/pages/api/gtag'
import { useThemeContext } from '@/contexts/ThemeContext'
import { StyledListContents } from '../index.style'
import {
  StyledImage,
  StyledImageList,
  StyledImageListContainer,
  StyledImageListDescription,
  StyledImageListItem,
  StyledImageListNonImg,
  StyledImageListTitle,
} from './index.style'

interface Props {
  id: number
  imageSrc?: string
  link: string
  title: string
  description?: string
}

const ImageList = ({ id, imageSrc, link, title, description }: Props) => {
  const { ref, inView } = useInView()
  const { isDark } = useThemeContext()

  const onClick = (id: number) => {
    event({
      action: 'click',
      category: 'creator_page_click',
      label: 'links',
      value: id,
    })
  }

  const imageListContent = () => (
    <StyledImageList isLink={link ? true : false} isDark={isDark}>
      <StyledImageListContainer>
        {imageSrc ? <StyledImage src={imageSrc} alt="image" /> : <StyledImageListNonImg />}

        <StyledImageListItem isExistItem={title && description ? true : false}>
          {title && (
            <StyledImageListTitle>
              <p className="jp-title middle">{title}</p>
            </StyledImageListTitle>
          )}
          {description && (
            <StyledImageListDescription>
              <p className="jp-text small">{description}</p>
            </StyledImageListDescription>
          )}
        </StyledImageListItem>
      </StyledImageListContainer>
    </StyledImageList>
  )

  return (
    <StyledListContents ref={ref} inView={inView}>
      {link || imageSrc ? (
        <a
          href={link || imageSrc}
          target="_blank"
          rel="noreferrer"
          onClick={() => onClick(id)}
          className={link?.includes('/apps/') ? 'ranking-anchor' : ''}
        >
          {imageListContent()}
        </a>
      ) : (
        <>{imageListContent()}</>
      )}
    </StyledListContents>
  )
}

export default ImageList
