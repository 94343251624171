import React from 'react'
import PageView from '@/assets/img/icons/PageView.svg'
import { event } from '@/pages/api/gtag'
import { useThemeContext } from '@/contexts/ThemeContext'
import { Colors } from '@/constants/styles/color'
import { StyledLink } from '../Contents/index.style'
import { StyledImageContainer, StyledImage, StyledText } from './index.style'

interface Props {
  id: number
  link?: string
  isBoxShadow?: boolean
  imageSrc?: string
  title?: string
  size: 'lg' | 'md' | 'sm' | 'xs'
}

const SquareContents = ({ id, link, isBoxShadow, imageSrc, title, size }: Props) => {
  const { themeColors, isDark } = useThemeContext()

  const onClick = (id: number) => {
    event({
      action: 'click',
      category: 'creator_page_click',
      label: 'links',
      value: id,
    })
  }

  return (
    <div>
      {link ? (
        <StyledLink
          href={link}
          target="_blank"
          onClick={() => onClick(id)}
          className={link?.includes('/apps/') ? 'ranking-anchor' : ''}
        >
          <StyledImageContainer size={size} boxShadow={isBoxShadow} isDark={isDark}>
            {imageSrc && <StyledImage src={imageSrc} alt="コンテンツ画像" size={size} />}
            {size === 'lg' && (
              <div
                style={{
                  position: 'absolute',
                  bottom: '0',
                  right: '0',
                  color: Colors.WHITE,
                  textShadow: '0px 0px 5px rgba(0, 0, 0, 0.1)',
                  letterSpacing: '0.1em',
                  fontFamily: 'Noto Sans JP',
                  fontStyle: 'normal',
                  fontWeight: '500',
                  fontSize: '10px',
                  lineHeight: '10px',
                  display: 'flex',
                  alignItems: 'flex-end',
                  margin: '8px',
                }}
              >
                VIEW
                <PageView
                  style={{
                    marginLeft: '2px',
                    fill: Colors.WHITE,
                    width: '24px',
                    height: '24px',
                  }}
                />
              </div>
            )}
          </StyledImageContainer>
          <StyledText size={size} themeColors={themeColors}>
            {title && title !== 'undefined' ? title : ''}
          </StyledText>
        </StyledLink>
      ) : (
        <>
          <StyledImageContainer size={size} boxShadow={isBoxShadow}>
            {imageSrc && <StyledImage src={imageSrc} alt="" size={size} />}
          </StyledImageContainer>
          <StyledText size={size} themeColors={themeColors}>
            {title && title !== 'undefined' ? title : ''}
          </StyledText>
        </>
      )}
    </div>
  )
}

export default SquareContents
